<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <b-col sm="1" lg="1" class="p-3">
          <b-button block @click="back()" variant="outline-secondary">
            <font-awesome-icon icon="arrow-circle-left" />
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <table-custom
            ref="table"
            :name="`chart_details_${this.sourceId}`"
            :loading="isLoading"
            :data="table.data"
            :options="table.options"
            @row-click="onRowClick"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  name: "ChartDetails",
  components: {
    TableCustom
  },
  props: {
    reportid: { type: String, default: "" },
    method: { type: String, default: "" }
  },

  data: function() {
    return {
      sourceId: undefined,
      isLoading: false,
      description: "",
      table: {
        data: [],
        columns: [],
        options: {
          columns: [],
          customSorting: {},
          perPage: 50,
          perPageValues: [10, 25, 50, 100, 500, 1000],
          //saveState: false,
          //storage:'local',
          autoTotals: true
          //  footerHeadings: true,
          //  summary: true
        },
        formatColumns: []
      }
    };
  },
  created() {
    const parent = this.$store.getters["drilldown.parent"];
    const method = this.$store.getters["drilldown.method"];
    this.sourceId = this.$helpers.str2_(`${parent}_${method}`);
  },
  mounted() {
    this.getData();
  },
  methods: {
    onRowClick: function(event) {
      /*
      this.$store.dispatch(
        "filterPanel.selected.accountname",
        event.row["Account Name"]
      );
      */
      let accountName = event.row["Account Name"] || event.row["Account name"];
      this.$form.prepareDrillDownFilters(undefined, accountName);

      this.$router.push({
        name: "Account overview"
      });
    },
    getData: async function() {
      let filter = this.$store.getters["drilldown.filter"];
      let method = this.$store.getters["drilldown.method"];
      this.table.formatColumns = this.$store.getters["drilldown.formatColumns"];

      this.isLoading = true;

      let data = await this.$api.data[method](filter);

      this.isLoading = false;

      this.table.data = data;
    },
    back: function() {
      this.$router.back();
    }
  }
};
</script>

<style></style>
