var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "p-3",
    attrs: {
      "sm": "1",
      "lg": "1"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.back();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "arrow-circle-left"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "table",
    attrs: {
      "name": "chart_details_".concat(this.sourceId),
      "loading": _vm.isLoading,
      "data": _vm.table.data,
      "options": _vm.table.options
    },
    on: {
      "row-click": _vm.onRowClick
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }